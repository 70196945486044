import classNames from "classnames";
import LiveImage from "components/left/LiveImage";
import { goLive, numberCommaString } from "helpers";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";

import { scriptLoad } from "libs/scriptLoad";
import { SHOPFREECA_DOMAIN, STATIC_DOMAIN } from "constant/config";


const Live = (props) => {

    const { broad } = props;
    const { broad_title, current_sum_viewer } = broad;
    const num = numberCommaString(current_sum_viewer);

    useEffect(() => {
        scriptLoad(`${STATIC_DOMAIN}/asset/service/config/constant.js`, { charset: "euc-kr" });
        scriptLoad(`https://requirejs.org/docs/release/2.3.6/comments/require.js`, () => {
            window.requirejs.config({
                baseUrl: `${SHOPFREECA_DOMAIN}/asset`,
                paths: {
                    jquery: ["library/jquery/1.10.2/jquery.min", "//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min"],
                    doT: "app/main/doT.min",
                    atv: "library/afreeca/atv.min-1.6",
                    afreeca: "service/afreeca.min-0.7",
                    "service/plugins/player": "service/afreeca.min-0.7",
                },
            });
        });
    })
    

    // [history1] afreecaPlayer.player.runBasecamp(); VR플레이어 로드 삭제 (안씀)

    return (
        <div className="onAir_box">
            <a href="" onClick={goLive(broad)}>
                <span className="img">
                    <LiveImage broad={broad} refresh={true} />
                </span>
                <span className="liveView">
                    { num }
                </span>
                <p className="sbj" title={broad_title}>
                    {broad_title}
                </p>
            </a>
            <div className={classNames("watchPlayer")} />
        </div>
    );
}

export default Live;
