import classNames from "classnames";
import React, { useCallback } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Profile from "components/left/Profile";
import {SETTING_LNB_PROFILE} from "constant/position.path";


const SettingMenu = (props) => {

    const getActive = useCallback((url) => {
        const { pathname } = props;
        return url === pathname ? "on" : null;
    },[props]);

    const { stationInfo } = props;
    const { data = {} } = stationInfo;
    const { station, is_partner_bj, is_best_bj, profile_image, is_owner = false, is_manager = false } = data;
    const { match } = props;
    const lang = document.documentElement.lang;

    return (
        <div id="bs-navi" className="">
            <div className="bs-navi_contents">
                <Profile
                    id = {match.params.id}
                    path = {match.path}
                    match={match}
                    station={station}
                    is_partner_bj={is_partner_bj}
                    is_best_bj={is_best_bj}
                    profile_image={profile_image}
                    position={SETTING_LNB_PROFILE}
                />
                {is_owner && (
                    <section className="menu-list a1">
                        <h3>
                            <span>
                                <Trans>채널 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            <li className={classNames(getActive(`${match.url}/basic`))}>
                                <Link to={`${match.url}/basic`}>
                                    <Trans>채널 기본 정보 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/home`))}>
                                <Link to={`${match.url}/home`}>
                                    홈 섹션 관리
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/title`))}>
                                <Link to={`${match.url}/title`}>
                                    <Trans>타이틀 이미지 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/link`))}>
                                <Link to={`${match.url}/link`}>
                                    <Trans>외부 링크 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/banner`))}>
                                <Link to={`${match.url}/banner`}>
                                    <Trans>배너 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/board`))}>
                                <Link to={`${match.url}/board`}>
                                    <Trans>게시판 관리</Trans>
                                </Link>
                            </li>
                            {lang === "ko" && (
                                <li className={classNames(getActive(`${match.url}/shopfreeca`))}>
                                    <Link to={`${match.url}/shopfreeca`}>
                                        <Trans>숲토어 영역 관리</Trans>
                                    </Link>
                                </li>
                            )}
                            <li className={classNames(getActive(`${match.url}/playlist`))}>
                                <Link to={`${match.url}/playlist`}>
                                    <Trans>재생목록 영역 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/banword`))}>
                                <Link to={`${match.url}/banword`}>
                                    <Trans>금칙어 관리</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
                {is_manager && (
                    <section className="menu-list a1">
                        <h3>
                            <span>
                                <Trans>채널 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            {/* 231124 - 매니저 접근 권한 제거 , 다시 생길 수도 있을 것 같아서 주석 */}
                            {/* {lang === "ko" && (
                                <li className={classNames(getActive(`${match.url}/shopfreeca`))}>
                                    <Link to={`${match.url}/shopfreeca`}>
                                        <Trans>숲토어 영역 관리</Trans>
                                    </Link>
                                </li>
                            )}
                            <li className={classNames(getActive(`${match.url}/playlist`))}>
                                <Link to={`${match.url}/playlist`}>
                                    <Trans>재생목록 영역 관리</Trans>
                                </Link>
                            </li> */}
                            <li className={classNames(getActive(`${match.url}/banword`))}>
                                <Link to={`${match.url}/banword`}>
                                    <Trans>금칙어 관리</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
                {is_owner && (
                    <section className="menu-list a2">
                        <h3>
                            <span>
                                <Trans>유저 / 권한 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            <li className={classNames(getActive(`${match.url}/fanclub`))}>
                                <Link to={`${match.url}/fanclub`}>
                                    <Trans>팬클럽 / 매니저 관리</Trans>
                                </Link>
                            </li>
                            <li
                                className={classNames(
                                    getActive(`${match.url}/subscription`),
                                    getActive(`${match.url}/supporter`),
                                )}
                            >
                                <Link to={`${match.url}/subscription`}>
                                    <Trans>구독팬 / 서포터 관리</Trans>
                                </Link>
                            </li>
                            <li
                                className={classNames(getActive(`${match.url}/vodauth`), getActive(`${match.url}/vodreject`))}
                            >
                                <Link to={`${match.url}/vodauth`}>
                                    <Trans>VOD 권한 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/editor`))}>
                                <Link to={`${match.url}/editor`}>
                                    <Trans>편집각 권한 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/blacklist`))}>
                                <Link to={`${match.url}/blacklist`}>
                                    <Trans>블랙리스트 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/userclip-blacklist`))}>
                                <Link to={`${match.url}/userclip-blacklist`}>
                                    <Trans>클립/Catch 블랙리스트 관리</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
                {is_manager && (
                    <section className="menu-list a2">
                        <h3>
                            <span>
                                <Trans>유저 / 권한 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            <li className={classNames(getActive(`${match.url}/blacklist`))}>
                                <Link to={`${match.url}/blacklist`}>
                                    <Trans>블랙리스트 관리</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
                {is_owner && (
                    <section className="menu-list a3">
                        <h3>
                            <span>
                                <Trans>개인 정보 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            <li className={classNames(getActive(`${match.url}/info`))}>
                                <Link to={`${match.url}/info`}>
                                    <Trans>개인 정보 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/profile`))}>
                                <Link to={`${match.url}/profile`}>
                                    <Trans>검색 정보 관리</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`${match.url}/medal`), getActive(`${match.url}/badge`))}>
                                <Link to={`${match.url}/medal`}>
                                    <Trans>메달/배지 관리</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
                {is_owner && (
                    <section className="menu-list a5">
                        <h3>
                            <span>
                                <Trans>VOD 광고 관리</Trans>
                            </span>
                        </h3>
                        <ul>
                            <li className={classNames(getActive(`${match.url}/midroll`))}>
                                <Link to={`${match.url}/midroll`}>
                                    <Trans>중간광고 설정</Trans>
                                </Link>
                            </li>
                        </ul>
                    </section>
                )}
            </div>
        </div>
    );
}

export default SettingMenu;