import produce from "immer";
import moment from "moment";

import { DELETE_POSTS_SUCCESS } from "actions/posts.action";
import { CANCEL_NOTICE_SUCCESS, DELETE_POST_LIKE_SUCCESS, DELETE_POST_SUCCESS, STORE_POST_LIKE_SUCCESS } from "actions/post.action";
import { DESTORY_PIN_SUCCESS, STORE_PIN_SUCCESS } from "actions/home.action";
import { CLEAR_POST_LIST, FETCH_POST_LIST, FETCH_POST_LIST_FAILURE, FETCH_POST_LIST_SUCCESS } from "actions/post.list.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";
import { NORMAL, PHOTO, UCC } from "constant/board";

const INITIAL_STATE = {
	status: CLEAR,
	error: null,
	pagination: {
		data: [],
		noticeData: [],
		links: {},
		meta: {},
	},
};

const postListReducer = produce((state, { type, payload }) => {
	switch (type) {
		// 게시글 리스트 로딩 시작
		case FETCH_POST_LIST:
			state.status = LOADING;
			return;

		// 게시글 리스트 조회 성공
		case FETCH_POST_LIST_SUCCESS:
			state.status = SUCCESS;
			state.pagination = {
				data: payload.data,
				noticeData: payload.notice_data || [],
				links: payload.links,
				meta: payload.meta,
			};
			return;

		// 게시글 리스트 조회 실패
		case FETCH_POST_LIST_FAILURE:
			state.status = ERROR;
			state.error = payload;
			return;

		// 게시글 리스트 초기화
		case CLEAR_POST_LIST:
			return INITIAL_STATE;

		// 게시글 삭제 성공
		case DELETE_POST_SUCCESS:
		case DELETE_POSTS_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}
			state.status = CLEAR;
			return;

		// 게시글 공지 해제
		case CANCEL_NOTICE_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			if(state.pagination.noticeData) {
				state.pagination.noticeData = state.pagination.noticeData.filter((post) => post.title_no !== payload.title_no);
			}
			state.pagination.data = state.pagination.data.map((post) => {
				if (post.title_no === payload.title_no) {
					post.authority.is_notice_cancelable = false;
				}
				return post;
			});
			return;

		// 게시글 좋아요 등록 성공
		case STORE_POST_LIKE_SUCCESS: {
			if (state.status !== SUCCESS) {
				return;
			}

			const { title_no: titleNo, user_id, copyright } = payload;
			const { user_id: copyright_id, user_nick: copyright_nick } = copyright || {};

			const setLike = (post) => {
				if (post.title_no === parseInt(titleNo)) {
					post.like = {
						reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
					};

					if (user_id === copyright_id) {
						post.copyright.like = {
							reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
						};

						post.copyright.like_id = copyright_id;
						post.copyright.like_nick = copyright_nick;
					}
				}

				return post;
			};

			state.pagination.data = state.pagination.data.map(setLike);
			state.pagination.noticeData = state.pagination.noticeData.map(setLike);
			return;
		}

		// 게시글 좋아요 해제 성공
		case DELETE_POST_LIKE_SUCCESS: {
			if (state.status !== SUCCESS) {
				return;
			}

			const { title_no: titleNo, user_id, copyright } = payload;
			const { user_id: copyright_id } = copyright || {};

			const removeLike = (post) => {
				if (post.title_no === parseInt(titleNo)) {
					post.like = null;

					if (user_id === copyright_id) {
						post.copyright.like = null;
					}
				}
				return post;
			};

			state.pagination.data = state.pagination.data.map(removeLike);
			state.pagination.noticeData = state.pagination.noticeData.map(removeLike);
			return;
		}

		// 핀 고정
		case STORE_PIN_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			const storePin = (post) => {
				// 일반 게시글, VOD 게시글 각각 한개씩만 핀 고정 가능
				const boardType = [NORMAL, PHOTO].includes(post.board_type) ? NORMAL : UCC;

				const { authority = {} } = post;

				if (post?.board_type === 105) {
					const pinable = authority.is_vod_pinable, // 핀 고정 권한을 갖고 있는지 여부
						pinFixed = post.title_no === payload.title_no; // 현재 고정 시킨 게시글

					// 고정 시킨 게시글은 핀 추가, 동일한 게시글 타입의 다른 게시글은 핀 제거
					post.pin = pinFixed ? { reg_date: moment().format("YYYY-MM-DD HH:mm:ss"), is_pin: true  } : null;
					post.authority.is_vod_pin_fixable = pinable && !pinFixed;
					post.authority.is_vod_pin_cancelable = pinable && pinFixed;
					return post;
				}

				const pinable = authority.is_pinable, // 핀 고정 권한을 갖고 있는지 여부
					pinFixed = post.title_no === payload.title_no; // 현재 고정 시킨 게시글

				// 고정 시킨 게시글은 핀 추가, 동일한 게시글 타입의 다른 게시글은 핀 제거
				post.pin = pinFixed ? { reg_date: moment().format("YYYY-MM-DD HH:mm:ss"), is_pin: true  } : null;
				post.authority.is_pin_fixable = pinable && !pinFixed;
				post.authority.is_pin_cancelable = pinable && pinFixed;

				return post;
			};

			state.pagination.data = state.pagination.data.map(storePin);
			state.pagination.noticeData = state.pagination.noticeData.map(storePin);
			return;

		// 핀 고정 해제
		case DESTORY_PIN_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			const deletePin = (post) => {

				let { authority = {} } = post;
				if (post.board_type === 105 && authority.is_vod_pinable) {
					post.authority.is_vod_pin_fixable = true;
					post.authority.is_vod_pin_cancelable = false;
					post.pin = null;
					return post;
				}

				if (!authority.is_pinable) {
					return post;
				}

				post.authority.is_pin_fixable = true;
				post.authority.is_pin_cancelable = false;
				post.pin = null;

				return post;
			};

			state.pagination.data = state.pagination.data.map(deletePin);
			state.pagination.noticeData = state.pagination.noticeData.map(deletePin);
			return;

		default:
	}
}, INITIAL_STATE);

export default postListReducer;
