import i18next from "i18next";

import { getCommentSearchType } from "constant/search";
import {
	PAGE_TYPE_REVIEW,
	PAGE_TYPE_PLAYLIST,
	PAGE_TYPE_ALL,
	PAGE_TYPE_NORMAL,
	PAGE_TYPE_USER,
	PAGE_TYPE_CLIP,
	PAGE_TYPE_MY_CLIP,
	PAGE_TYPE_STARBALLOON_CLIP,
    PAGE_TYPE_CATCH,
	PAGE_TYPE_CATCH_STORY
} from "constant/ucc.page.type";

export const ORDER_REG_DATE = "reg_date";
export const ORDER_REG_DATE_ASC = "reg_date_asc";
export const ORDER_READ_CNT = "read_cnt";
export const ORDER_COMMENT_CNT = "comment_cnt";
export const ORDER_LIKE_CNT = "like_cnt";
export const ORDER_LIVE_PV = "live_pv";
export const ORDER_VOD_ADD_DATE = "vod_add_date";
export const ORDER_POPULAR = "popular";
export const ORDER_GIFT_CNT = "gift_cnt";

export const ORDER_REG_DATE_NAME = () => i18next.t("최신순");
export const ORDER_REG_DATE_ASC_NAME = () => i18next.t("오래된순");
export const ORDER_READ_CNT_NAME = () => i18next.t("조회순");
export const ORDER_COMMENT_CNT_NAME = () => i18next.t("댓글순");
export const ORDER_LIKE_CNT_NAME = () => i18next.t("UP순");
export const ORDER_LIVE_PV_NAME = () => i18next.t("라이브참여순");
export const ORDER_VOD_ADD_DATE_NAME = () => i18next.t("VOD 추가순");
export const ORDER_POPULAR_NAME = () => i18next.t("인기순");
export const ORDER_GIFT_CNT_NAME = () => i18next.t("선물 개수순");

/**
 * 게시글 정렬 옵션 조회
 * @param field
 * @param pageType
 * @returns {[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}]|[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}]|[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}]|[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, null]}
 */
export const getOrderOptions = (field, pageType = "post", catchCreated = false) => {
	switch (pageType) {
		// VOD 게시판
		case PAGE_TYPE_ALL:
		case PAGE_TYPE_NORMAL:
		case PAGE_TYPE_USER:
		case PAGE_TYPE_MY_CLIP:
		case PAGE_TYPE_STARBALLOON_CLIP:
			return [
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
				{ value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
				{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
			];

		// 라이브 생성 vod (다시보기, 하이라이트) 게시판
		case PAGE_TYPE_REVIEW:
			return [
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
				{ value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
				{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
				{ value: ORDER_LIVE_PV, name: ORDER_LIVE_PV_NAME() },
			];

		// 유저 클립 게시판 정렬
		case PAGE_TYPE_CLIP:
			return [
				{ value: ORDER_POPULAR, name: ORDER_POPULAR_NAME() },
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
				{ value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
				{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
			];

		// 재생목록 게시판
		case PAGE_TYPE_PLAYLIST:
			return [
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_VOD_ADD_DATE, name: ORDER_VOD_ADD_DATE_NAME() },
			];
		// 캐치 게시판 정렬
		case PAGE_TYPE_CATCH:
            /* 다시 살려달라고 할까봐 주석해둠
            if(catchCreated) {
                return [
                    { value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
                    { value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
                    { value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
                    { value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
                    { value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() }
                ];
            }
            */
			return [
				{ value: ORDER_POPULAR, name: ORDER_POPULAR_NAME() },
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
				{ value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
				{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
			];
		// 캐치 스토리 게시판 정렬
		case PAGE_TYPE_CATCH_STORY:
			return [
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
			];

		// 일반 게시글 게시판
		default:
			// 댓글 검색
			if (getCommentSearchType().indexOf(field) >= 0) {
				return [
					{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
					{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
					{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
					{ value: ORDER_GIFT_CNT, name: ORDER_GIFT_CNT_NAME()}
				];
			}

			// 일반 게시글
			return [
				{ value: ORDER_REG_DATE, name: ORDER_REG_DATE_NAME() },
				{ value: ORDER_REG_DATE_ASC, name: ORDER_REG_DATE_ASC_NAME() },
				{ value: ORDER_READ_CNT, name: ORDER_READ_CNT_NAME() },
				{ value: ORDER_COMMENT_CNT, name: ORDER_COMMENT_CNT_NAME() },
				{ value: ORDER_LIKE_CNT, name: ORDER_LIKE_CNT_NAME() },
			];
	}
};

/**
 * 유효한 정렬 옵션 필터링
 * @param order
 * @param field
 * @param pageType
 * @returns {*}
 */
export const getOrder = (order, field, pageType, catchCreated = false) => {
	const orders = getOrderOptions(field, pageType, catchCreated).map((option) => option.value);
	return orders.find((optionField) => optionField === order) || orders[0];
};
