import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import "libs/immer";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { isMobile, isIE, browserVersion } from "react-device-detect";

import loadStyleSheet from "libs/loadStyleSheet";

import App from "App";
import * as serviceWorker from "serviceWorker";

import store from "store";
import Sentry from "Sentry";

import "i18n";
import { RES_DOMAIN } from "constant/config";

//IE 종료
if (window.navigator.userAgent.match(/MSIE|Internet Explorer|Trident/i)) {
    window.location = 'microsoft-edge:' + window.location;
    setTimeout(function () {
        window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
    }, 1);
}

if (!(isIE && browserVersion < 10)) {
	if (isMobile) {
		loadStyleSheet(`${RES_DOMAIN}/css/global/mobile/bj/mobile_bj.css`).then(() => {
			reactDomRender();
		});
	} else {
		loadStyleSheet(`${RES_DOMAIN}/css/global/bj.css`).then(() => {
			reactDomRender();
		});
	}

	const reactDomRender = () => {
		ReactDOM.render(
			<Provider store={store}>
				<Sentry>
					<App />
				</Sentry>
			</Provider>,
			document.getElementById("root"),
		);
	};
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: http://bit.ly/CRA-PWA
	serviceWorker.unregister();
}
