import React, { useState, useEffect, useRef, useCallback } from "react";
import {useHistory} from "react-router";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import ReactPopup from "./popup/ReactPopup";

import { sendNote } from "helpers";
import { searchLog } from "libs/searchLogCollection";
import { NICKNAME_USER_ID } from "../constant/search";
import { STYPE_USER_ID } from "../constant/log";

import { destroyFavorite, showFavorite, storeFavorite } from "actions/station.action";
import { STATION_DOMAIN } from "../constant/config";

const HomeNickNameIctMenu = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const menuLayerRef = useRef(null);

    const [isFavorite, setIsFavorite] = useState(false);

    const { open, item, top, left } = useSelector(({ homeIctMenuReducer }) => ({
        open: homeIctMenuReducer.open,
        item: homeIctMenuReducer.menuData,
        top: homeIctMenuReducer.top,
        left: homeIctMenuReducer.left,
    }));

    const { user_id = '' } = useSelector(({ authenticationReducer }) => {
            return {
                user_id: authenticationReducer.user_id,
            };
        }
    );

    useEffect(() => {
        const handleOutSideClick = (e) =>{
            e.stopPropagation();
            if (open && menuLayerRef.current && !menuLayerRef.current.contains(e.target)) {
                dispatch({type: 'ICT_CLOSE_MODAL'});
            }
        }

        const handleResize = (e) => {
            e.stopPropagation();
            if (open) {
                dispatch({ type: 'ICT_CLOSE_MODAL' });
            }
        };

        window.addEventListener('click', handleOutSideClick);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener("click", handleOutSideClick);
            window.removeEventListener('resize', handleResize);
        }
    }, [menuLayerRef, open, dispatch]);

    useEffect(() => {
        if (open) {
            handlePopupOpen();
        }
    }, [open]);

    const handleSearch = (event) => {
        event.preventDefault();

        searchLog({keyword: item.user_id, filter: NICKNAME_USER_ID, searchType: STYPE_USER_ID});
        history.push(`/${global.bj_id}/search?keyword=${item.user_id}&type=total&field=${NICKNAME_USER_ID}`, { searchType: STYPE_USER_ID });

        dispatch({type: 'ICT_CLOSE_MODAL'});
    }

    const handlePopupOpen = (e) => {
        showFavorite(item.user_id, (response) => {
            setIsFavorite(response.is_favorite);
        });
    }

    const handleFavorite = useCallback((e) => {
        e.stopPropagation();

        if (isFavorite) {
            dispatch(destroyFavorite(item.user_id));
        } else {
            dispatch(storeFavorite(item.user_id));
        }
        dispatch({type: 'ICT_CLOSE_MODAL'});
    }, [isFavorite, item])

    const handleSendNote = (e) => {
        e.stopPropagation();

        sendNote(item.user_id);
        dispatch({type: 'ICT_CLOSE_MODAL'});
    }

    const handleGoStation = (e) => {
        e.stopPropagation();

        if (item.user_id) {
            window.open(`${STATION_DOMAIN}/${item.user_id}`);
            dispatch({type: 'ICT_CLOSE_MODAL'});
        }
    }

    if (!open) {
        return  null;
    }

    return (
        <div className="icon-menu" ref={menuLayerRef} style={{ position: "absolute", top: top, left: left, right: "auto" }}>
            <button type="button" className="bj_bs" tip={"채널"} onClick={handleGoStation}>
                <span>
                    채널 가기
                </span>
            </button>
            {item.user_id !== user_id && (
                <button type="button" className="send_memo" tip={"쪽지 보내기"} onClick={handleSendNote}>
                    <span>
                        쪽지 보내기
                    </span>
                </button>
            )}
            {item.user_id !== user_id && (
                <button
                    type="button"
                    className={classNames("favor", { on: isFavorite })}
                    tip={isFavorite ? "즐겨찾기 삭제" : "즐겨찾기 추가"}
                    onClick={handleFavorite}
                >
                    <span>
                        즐겨찾기
                    </span>
                </button>
            )}

            <button type="button" className="srh" tip={"채널 게시글/VOD 검색"} onClick={handleSearch}>
                <span>
                    채널 게시글/VOD 검색
                </span>
            </button>
        </div>
    );
}

export default HomeNickNameIctMenu;