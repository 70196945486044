import React, { useEffect, useRef } from "react";
import { storeLaterViewVod } from "../actions/post.action";
import { useSelector, useDispatch } from "react-redux";
import { h } from "preact";

const HomeVodLaterButton = () => {
    const dispatch = useDispatch();
    const menuLayerRef = useRef(null);

    const { laterButtonOpen, titleNo, top, left } = useSelector(({ homeIctMenuReducer }) => ({
        laterButtonOpen: homeIctMenuReducer.laterButtonOpen,
        titleNo: homeIctMenuReducer.titleNo,
        top: homeIctMenuReducer.top,
        left: homeIctMenuReducer.left
    }));

    const handleStoreLaterVod = (event) => {
        event.preventDefault();
        storeLaterViewVod(titleNo, "VOD");
        dispatch({type: 'LATER_CLOSE_MODAL'});
    }

    useEffect(() => {
        const handleOutSideClick = (e) => {
            e.stopPropagation();
            if (laterButtonOpen && menuLayerRef.current && !menuLayerRef.current.contains(e.target)) {
                dispatch({ type: 'LATER_CLOSE_MODAL' });
            }
        };

        const handleResize = (e) => {
            e.preventDefault();
            if (laterButtonOpen) {
                dispatch({ type: 'LATER_CLOSE_MODAL' });
            }
        };

        window.addEventListener('click', handleOutSideClick);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('click', handleOutSideClick);
            window.removeEventListener('resize', handleResize);
        };
    }, [menuLayerRef, laterButtonOpen, dispatch]);

    return (
        laterButtonOpen ?
            (
                <div ref={menuLayerRef} className={'more-layer'} style={{ position: 'absolute', top: top, left: left }}>
                    <button type="button" onClick={handleStoreLaterVod}>
                        <span>
                            나중에 보기
                        </span>
                    </button>
                </div>
            ): null);
}

export default HomeVodLaterButton;