import React, { useEffect, useState } from "react";
import moment from "moment-jalaali";
import { useDispatch } from "react-redux";
import { _realStoreHopin } from "actions/home.action";
import { useHistory } from "react-router";
import { toastr } from "components/toastr";
import HelmetTitle from "components/HelmetTitle";
const MobilePinSetting = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const formatDisplayDateTime = (dateTime) => {
        const hour = moment(dateTime).hour();
        const period = hour < 12 ? "오전" : "오후";
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return moment(dateTime).format(`YYYY-MM-DD ${period} ${formattedHour}:mm`);
    };

    const [dateTime, setDateTime] = useState(moment().add(1, 'days').format("YYYY-MM-DD HH:mm"));
    const [expireSetting, setExpireSetting] = useState(false);

    const handleDateTimeChange = (e) => {
        setDateTime(moment(e.target.value).format('YYYY-MM-DD HH:mm'));
    };

    const handleExpireCheck = (event) => {
        setExpireSetting(event.target.checked);
    };

    const handleStorePin = () => {
        const titleNo = props.location.state.titleNo || 0;
        const boardType = props.location.state.boardType || 0;
        const pageType = props.location.state.currentPageType || '';
        const created = props.location.state.created || false;

        // "오전/오후" 포함된 시간 파싱 및 24시간 형식으로 변환
        const formattedExpireDate = expireSetting
            ? null
            : moment(dateTime, 'YYYY-MM-DD A HH:mm').format('YYYY-MM-DD HH:mm'); // 24시간 형식으로 변환

        const selectedDateTime = moment(formattedExpireDate, 'YYYY-MM-DD HH:mm');
        const currentDateTime = moment();

        if (!expireSetting && selectedDateTime.isBefore(currentDateTime)) {
            toastr.success('현재시간 이후를 선택해 주세요.');
            return;
        }

        _realStoreHopin(dispatch, titleNo, boardType, expireSetting ? null : formattedExpireDate, history, pageType, created);
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <>
            <HelmetTitle title={''} />
            <article id="vote_wrap" className="vote-mobile pinExpire">
                <header>
                    <section className="basic-section">
                        <div className="grid-start">
                            <button type="button" className="btn-back" onClick={handleGoBack}>
                                <span>뒤로가기</span>
                            </button>
                        </div>
                        <h2 className="title">
                            <p>홈 고정 만료일 등록</p>
                        </h2>
                        <div className="group-end">
                            <button type="button" onClick={handleStorePin}><span>적용</span></button>
                        </div>
                    </section>
                </header>

                <div className="vote-body">
                    <div className="vote-desc">
                        <p>홈 고정 콘텐츠는 설정한 만료일 이후에는 자동으로 해지됩니다.</p>
                    </div>
                    <div className="vote-setting">
                        <dl className={`date ${expireSetting ? 'disabled' : ''}`}>
                            <dt>홈 고정 만료일</dt>
                            <dd>
                                <div className="date_mo">
                                    <input
                                        type="datetime-local"
                                        value={moment(dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={handleDateTimeChange}
                                        disabled={expireSetting}
                                    />
                                    <label htmlFor="datetime" className="datetime">
                                        {formatDisplayDateTime(dateTime)}
                                    </label>
                                </div>
                            </dd>
                        </dl>
                        <dl className="setting">
                            <dt>설정</dt>
                            <dd>
                                <div className="check">
                                    <input type="checkbox" id="set01" onChange={handleExpireCheck} />
                                    <label htmlFor="set01">만료 설정 안함</label>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </article>
        </>

    );
};

export default MobilePinSetting;
