import { wrapClassName } from "actions/page.action";
import { fetchStationInfo } from "actions/station.action";
import Footer from "components/footer/Footer";
import SettingMenu from "components/left/SettingMenu";
import LoadingMobile from "components/loading/LoadingMobile";
import HelmetTitle from "components/HelmetTitle";
import { documentScrollTop, isWebview } from "helpers";
import { StationError } from "pages/station/error/StationError";
import { STATION_DOMAIN } from "constant/config";
import { StationErrorMobile } from "pages/station/error/StationErrorMobile";
import React, { Component, Suspense } from "react";
import cookie from "react-cookies";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { callAU } from "libs/scriptLoad";
import {APP_SCHEME} from "constant/config";

const Banners = React.lazy(() => import("pages/setting/banner/Banners"));
const BottomBanners = React.lazy(() => import("pages/setting/banner/BottomBanners"));
const Banwords = React.lazy(() => import("pages/setting/banword/Banwords"));
const BasicInfo = React.lazy(() => import("pages/setting/basic/BasicInfo"));
const MyInfo = React.lazy(() => import("pages/setting/basic/MyInfo"));
const Channelart = React.lazy(() => import("pages/setting/channelart/Channelart"));
const AutoManager = React.lazy(() => import("pages/setting/fanclub/AutoManager"));
const Fanclub = React.lazy(() => import("pages/setting/fanclub/Fanclub"));
const StationManager = React.lazy(() => import("pages/setting/fanclub/StationManager"));
const Links = React.lazy(() => import("pages/setting/link/Links"));
const Medals = React.lazy(() => import("pages/setting/medal/Medals"));
const Badges = React.lazy(() => import("pages/setting/badge/Badges"));
const Menu = React.lazy(() => import("pages/setting/menu/Menu"));
const Shopfreeca = React.lazy(() => import("pages/setting/shopfreeca/Shopfreeca"));
const ShopfreecaAddLayer = React.lazy(() => import("pages/setting/shopfreeca/ShopfreecaAddLayer"));
const SettingPlaylistAddLayer = React.lazy(() => import("pages/setting/playlist/SettingPlaylistAddLayer"));
const SettingPlaylist = React.lazy(() => import("pages/setting/playlist/SettingPlaylist"));
const MyRevenue = React.lazy(() => import("pages/setting/myrevenue/MyRevenue"));
const SearchProfile = React.lazy(() => import("pages/setting/search/SearchProfile"));
const SettingMenuMobile = React.lazy(() => import("pages/setting/SettingMenuMobile"));
const Statistics = React.lazy(() => import("pages/setting/statistics/Statistics"));
const Blacklist = React.lazy(() => import("pages/setting/user/Blacklist"));
const UserclipBlacklist = React.lazy(() => import("pages/setting/user/UserclipBlacklist"));
const Subscript = React.lazy(() => import("pages/setting/user/Subscript"));
const Supporter = React.lazy(() => import("pages/setting/user/Supporter"));
const VodAuth = React.lazy(() => import("pages/setting/user/vod/auth/Auth"));
const VodAuthMobile = React.lazy(() => import("pages/setting/user/vod/auth/AuthMobile"));
const VodReject = React.lazy(() => import("pages/setting/user/vod/reject/Reject"));
const Vodeditor = React.lazy(() => import("pages/setting/user/Vodeditor"));
const MidRoll = React.lazy(() => import("pages/setting/vodCommercial/MidRoll"));
const MidRollMobile = React.lazy(() => import("pages/setting/vodCommercial/MidRollMobile"));
const SettingHome = React.lazy(() => import("pages/setting/home/Home"));
class Setting extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        global.bj_id = match.params.id.toLowerCase();
        this.props.fetchStationInfo(global.bj_id);
    }

    componentDidMount() {
        //페이지이동할때 이벤트를 받음
        const { history } = this.props;
        this.location = this.props.location;
        this.unsubscribeFromHistory = history.listen(() => {
            const prev_referer = this.props.location.pathname
            this.handleLocationChange(history.location, prev_referer);
        });
        this.handleLocationChange(history.location);

        //관리페이지 클래스
        let className = ['admin'];
        if(this.props.location.pathname === `/${global.bj_id}/setting/home`) {
            className.push('_homeSection')
        }
        this.props.wrapClassName(className); //클래스 초기화

        const theme = cookie.load("theme");
        if (theme === "dark" && document.documentElement.lang === "ko") {
            const html = document.getElementsByTagName('html')[0];
            if (html) {
                html.setAttribute('dark', 'true');
            }
        }
    }

    /**
     * 페이지 이동시 하위페이지에서 ContentsHeader를 축소시킴
     * @param {*} location
     */
    handleLocationChange = (location, prev_referer) => {
        /**
         * 페이지 이동시킬때만
         */
        let pathName = location.pathname?.split('/')[2];
        if (location.pathname === `/${global.bj_id}/setting/home`) {
            this.props.wrapClassName(["admin", "_homeSection"]); //클래스 초기화
        } else if (location.pathname === `/${global.bj_id}`) {
            this.props.wrapClassName(["main", "renew241121_common", "renew241121"]); //클래스 초기화
        } else if (location.pathname === `/${global.bj_id}/post/write`) {
            this.props.wrapClassName(["renew241121_common"]); //클래스 초기화
        } else if (pathName === 'setting') {
            this.props.wrapClassName(["admin"]); //클래스 초기화
        } else {
            this.props.wrapClassName(["renew241121_common"]); //클래스 초기화
        }

        if (isWebview) {
            //웹뷰에게 페이지 이동을 알림
            window.location = `${APP_SCHEME}browser/loaded`;
        }
        
        let referrer = STATION_DOMAIN + prev_referer;
        callAU("", referrer);

        if (!isMobile) {
            documentScrollTop();
        }
    };

    render() {
        const { t, match, stationInfo } = this.props;
        const { loading, error, data = {} } = stationInfo;
        const { is_owner = false, is_manager = false } = data;
        if (isMobile) {
            if (loading) {
                return <LoadingMobile />;
            }
            if (error) {
                return <StationErrorMobile error={error} />;
            }
            if (!is_owner && !is_manager) {
                return <StationErrorMobile error={{ code: 9900, message: t("접근 권한이 없습니다.") }} />;
            }
            return (
                <>
                    <Suspense fallback={<div className="af_loading more"></div>}>
                    <HelmetTitle title = {t("채널 관리")} />
                        <Switch>
                            <Route path={`${match.path}/basic`} exact component={BasicInfo} />
                            <Route path={`${match.path}/home`} exact component={SettingHome} />
                            <Route path={`${match.path}/link`} component={Links} />
                            <Route path={`${match.path}/board`} exact component={Menu} />
                            <Route path={`${match.path}/shopfreeca/add`}  render={(props) => <ShopfreecaAddLayer {...props} />} />
                            <Route path={`${match.path}/shopfreeca`}  render={(props) => <Shopfreeca {...props} />} />
                            <Route path={`${match.path}/playlist/add`} component={SettingPlaylistAddLayer} />
                            <Route path={`${match.path}/playlist`} component={SettingPlaylist} />
                            <Route path={`${match.path}/automanager`} exact component={AutoManager} />
                            <Route path={`${match.path}/manager`} exact component={StationManager} />
                            <Route path={`${match.path}/vodauth`} component={VodAuthMobile} />
                            <Route path={`${match.path}/editor`} exact component={Vodeditor} />
                            <Route path={`${match.path}/blacklist`} exact component={Blacklist} />
                            <Route path={`${match.path}/userclip-blacklist`} exact component={UserclipBlacklist} />
                            <Route path={`${match.path}/midroll`} exact component={MidRollMobile} />
                            <Route path={`${match.path}/banner`} component={BottomBanners} />
                            <Route path={`${match.path}/banword`} exact component={Banwords} />
                            <Route path={`${match.path}`} render={(props) => <SettingMenuMobile {...props} stationInfo={stationInfo} />} />
                        </Switch>
                    </Suspense>
                </>
            );
        } else {
            if (loading) {
                return <div className="af_loading more" />;
            }
            if (error) {
                return <StationError error={error} />;
            }
            if (!is_owner && !is_manager) {
                return <StationError error={{ code: 9900, message: t("접근 권한이 없습니다.") }} />;
            }

            return (
                <div id="bs-container">
                    <SettingMenu match={match} stationInfo={stationInfo} pathname={this.props.location.pathname} />
                    <div id="bs-contents">
                        <Suspense fallback={<div className="af_loading more"></div>}>
                        <HelmetTitle title = {t("채널 관리")} />
                            <Switch>
                                {is_owner && <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.url}/basic`} />} />}
                                {is_manager && (
                                    <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.url}/blacklist`} />} />
                                )}
                                <Route path={`${match.path}/basic`} component={BasicInfo} />
                                <Route path={`${match.path}/home`} component={SettingHome} />
                                <Route path={`${match.path}/title`} component={Channelart} />
                                <Route path={`${match.path}/medal`} component={Medals} />
                                <Route path={`${match.path}/badge`} component={Badges} />
                                <Route path={`${match.path}/link`} component={Links} />
                                <Route path={`${match.path}/banner`} component={Banners} />
                                <Route path={`${match.path}/board`} component={Menu} />
                                <Route path={`${match.path}/shopfreeca`}  render={(props) => <Shopfreeca {...props} />} />
                                <Route path={`${match.path}/playlist`} component={SettingPlaylist} />
                                <Route path={`${match.path}/banword`} component={Banwords} />
                                <Route path={`${match.path}/fanclub`} component={Fanclub} />
                                <Route path={`${match.path}/subscription`} component={Subscript} />
                                <Route path={`${match.path}/supporter`} component={Supporter} />
                                <Route path={`${match.path}/vodauth`} component={VodAuth} />
                                <Route path={`${match.path}/vodreject`} component={VodReject} />
                                <Route path={`${match.path}/editor`} component={Vodeditor} />
                                <Route path={`${match.path}/blacklist`} component={Blacklist} />
                                <Route path={`${match.path}/userclip-blacklist`} component={UserclipBlacklist} />
                                <Route path={`${match.path}/info`} component={MyInfo} />
                                <Route path={`${match.path}/profile`} component={SearchProfile} />
                                <Route path={`${match.path}/statistics`} component={Statistics} />
                                <Route path={`${match.path}/myrevenue`} component={MyRevenue} />
                                <Route path={`${match.path}/midroll`} component={MidRoll} />
                            </Switch>
                        </Suspense>
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stationInfo: state.stationReducer.stationInfo,
        pageReducer: state.pageReducer,
    };
};
// props 값으로 넣어 줄 상태를 정의해줍니다.
const mapDispatchToProps = (dispatch) => {
    return {
        wrapClassName: (classname) => {
            dispatch(wrapClassName(classname));
        },
        fetchStationInfo: (id) => {
            return dispatch(fetchStationInfo(id));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Setting));
