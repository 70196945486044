import debounce from "lodash.debounce";
import throttle from "lodash.throttle";
import React, { useCallback, useEffect, useRef } from "react";
import { isIOS } from "react-device-detect";
import { withRouter } from "react-router";

const setScrollTop = (value) => {
    const element = document.documentElement && isIOS === false ? document.documentElement : document.body;
    element.scrollTop = value;
};
const getScrollTop = () => {
    const element = document.documentElement && isIOS === false ? document.documentElement : document.body;
    return element.scrollTop;
};

const replaceState = (state) => {
    const windowState = window.history.state ? window.history.state.state : {};
    window.history.replaceState(
        {
            ...window.history.state,
            state: { ...windowState, ...state },
        },
        null,
    );
};

const getState = () => {
    return window.history.state ? window.history.state.state || {} : {};
};

const setTimeoutCount = (callback, delay, count) => {
    let remainingTime = count;
    return window.setTimeout(() => {
        callback();
        if (remainingTime > 1) {
            setTimeoutCount(callback, delay, remainingTime - 1);
        }
    }, delay);
};

const RestoredScroll = withRouter(({ children, className, history }) => {
    const [minHeight, setMinHeight] = React.useState(null);
    const containerRef = useRef(null);
    const observerRef = useRef(null); // MutationObserver 저장

    useEffect(() => {
        const { clientHeight } = getState();
        setMinHeight(history.action === "PUSH" ? 0 : clientHeight);
    }, [history.action]);

    useEffect(() => {
        const { scrollTop = 0, force = false, disableScroll = false } = getState();
        const landingComment = window.location.hash.match(/^(#comment(_noti)?)([0-9]+)$/) || '';
        const landingReply = window.location.hash.match(/^(#reply(_noti)?)([0-9]+)$/) || '';
        const commentArr = ['#comment', '#comment_noti'];
        const replyArr = ['#reply', '#reply_noti'];

        if (
            disableScroll === false &&
            !commentArr.includes(landingComment[1]) &&
            !replyArr.includes(landingReply[1])
        ) {
            setTimeoutCount(
                () => {
                    setScrollTop(history.action === "PUSH" && force === false ? 0 : scrollTop);
                },
                100,
                3,
            );
        }
    }, [history.action, minHeight, history.location.pathname]);

    const handleScroll = useCallback(
        debounce(() => {
            replaceState({ scrollTop: getScrollTop() });
        }, 200),
        [],
    );

    const handleResize = useCallback(
        throttle(() => {
            if (containerRef.current) {
                replaceState({ clientHeight: containerRef.current.clientHeight });

                setTimeout(() => {
                    if (containerRef.current && containerRef.current.clientHeight > containerRef.current.children[0].clientHeight) {
                        setMinHeight(containerRef.current.children[0].clientHeight);
                    }
                }, 1000);
            }
        }, 200),
        [],
    );

    useEffect(() => {
        observerRef.current = new MutationObserver(() => {
            handleResize();
        });

        if (containerRef.current) {
            observerRef.current.observe(containerRef.current, { childList: true, subtree: true });
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [handleResize, handleScroll]);

    return (
        <div className={className} ref={containerRef} style={{ minHeight: minHeight }}>
            <div>{children}</div>
        </div>
    );
});

export default RestoredScroll;
