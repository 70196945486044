import classNames from "classnames";
import ChannelArt from "components/contents/ChannelArt";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import Info from "./info/Info";

import {getActiveEvent} from "helpers";

class ContentsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoExpansion: props.homeReducer.home,
            home: props.homeReducer.home,
            adballoonEvent: false,
            isActiveEvent: false
        };
        this.navOffsetTop = 0;
        this.infoRef = React.createRef();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.homeReducer.home !== nextProps.homeReducer.home) {
            this.setState({
                home: nextProps.homeReducer.home,
                infoExpansion: nextProps.homeReducer.home, //홈으로 오면 바로 확장
            });
        }

        if(nextProps.homeReducer.payload) {
            this.isActiveGiftEvent(nextProps.homeReducer.payload);
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (isMobile) {
        } else {
            const { home } = this.state;
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const scrollX = window.scrollX || document.documentElement.scrollLeft;
            const infoExpansion = scrollY <= this.infoRef.current.offsetTop && home  ? true : false;

            this.setState((prevState) => {
                if (prevState.infoExpansion !== infoExpansion) {
                    this.props.onExtend();
                }
                return {
                    infoExpansion,
                    scrollX: infoExpansion ? 0 : 0 - scrollX, //Info 에 좌측좌표
                };
            });
        }
    };

    handleTabChange = (index) => (e) => {
        if (this.props.onTabChange) {
            this.props.onTabChange(index);
        }
    };

    isActiveGiftEvent = (adballoonEvent) => {
        if(!adballoonEvent.adballoon_event.end_yn) {
            if(adballoonEvent.adballoon_event.adballoon_event_bj_list) {
                this.setState({ isActiveEvent: true });
                this.setState({ adballoonEvent: adballoonEvent.adballoon_event });
            }
        }
    }

    render() {
        const { stationInfo, match, homeReducer } = this.props;
        const { infoExpansion, scrollX } = this.state;
        const { payload } = homeReducer;
        const style = {
            marginLeft: scrollX,
        };
        return (
            <>
            <article className={classNames("bs-contents-header", { expansion: infoExpansion })} style={style}>
                <ChannelArt match={match} stationInfo={stationInfo}  isActiveEvent={this.state.isActiveEvent} adballoonEvent={this.state.adballoonEvent}  />

                <Info
                    infoRef={this.infoRef}
                    payload={payload}
                    stationInfo={stationInfo}
                    onExtend={this.props.onExtend}
                    onDidUpdate={this.props.onDidUpdate}
                    isActiveEvent={this.state.isActiveEvent}
                    adballoonEvent={this.state.adballoonEvent}
                />
            </article>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        homeReducer: state.homeReducer,
        authenticationReducer: state.authenticationReducer
    };
};

export default connect(mapStateToProps)(ContentsHeader);
