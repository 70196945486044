import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";
import { BJ_API_DOMAIN } from "constant/config";
import { UCC } from "../constant/board";
import { POST, DELETE } from "../constant/method";
import { toastr } from "components/toastr";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { isMobile } from "react-device-detect";

export const HOME_DID_MOUNT = "HOME_DID_MOUNT";
export const HOME_WILL_UNMOUNT = "HOME_WILL_UNMOUNT";

export const MOBILE_FLOATING_OEPN = "MOBILE_FLOATING_OEPN";
export const MOBILE_FLOATING_CLOSE = "MOBILE_FLOATING_CLOSE";

export const FETCH_HOME = "FETCH_HOME";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE";

//홈에고정 추가
export const STORE_PIN = "STORE_PIN";
export const STORE_PIN_SUCCESS = "STORE_PIN_SUCCESS";

export const DESTORY_PIN = "DESTORY_PIN";
export const DESTORY_PIN_SUCCESS = "DESTORY_PIN_SUCCESS";

export const STORE_PIN_BOTTOM_SUCCESS = "STORE_PIN_BOTTOM_SUCCESS";
export const DESTORY_PIN_BOTTOM_SUCCESS = "DESTORY_PIN_BOTTOM_SUCCESS";

export const COMMENTING = "COMMENTING";

export function commenting(flag) {
	return (dispatch) => {
		dispatch({
			type: COMMENTING,
			payload: flag,
		});
	};
}

export function homeDispatch(type) {
	return (dispatch) => {
		dispatch({ type });
	};
}
export function fetchHome() {
	return (dispatch) => {
		dispatch({ type: FETCH_HOME });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/home`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_HOME_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_HOME_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}



/**
 * 홈에고정 추가
 * @param {*} bj_id
 */
export function storeHomePin(title_no, board_type, expireDate = null, history = null, pageCreated = false) {
	return (dispatch) => {
		dispatch({ type: STORE_PIN });

		let pageType = '';
		if (board_type === 105) {
			let pagePattern = window.location.pathname.split('/');

			if (typeof pagePattern[2] !== 'undefined' && pagePattern[2] === 'vods') {
				pageType = typeof pagePattern[3] !== 'undefined' ? pagePattern[3] : 'all';
			}
		}


		const checkUrl = `${BJ_API_DOMAIN}/${global.bj_id}/home/pin?title_no=${title_no}&board_type=${board_type}${board_type === 105 ? `&page_type=${pageType}&created=${pageCreated}` : ''}`;

		//로딩
		fetch(checkUrl, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				if (response.is_pin === 0) {
					if (isMobile) {
						history.push(`/${global.bj_id}/homePin`, {titleNo: title_no, boardType: board_type, currentPageType: pageType, created: pageCreated});
					} else {
						dispatch({type: "OPEN_MODAL", payload: {titleNo: title_no, boardType: board_type, currentPageType: pageType, created: pageCreated}})
					}
				} else {
					if (window.confirm(response?.message)) {
						// _realStoreHopin(dispatch, title_no, board_type);
						if (isMobile) {
							history.push(`/${global.bj_id}/homePin`, {titleNo: title_no, boardType: board_type, currentPageType: pageType, created: pageCreated});
						} else {
							dispatch({type: "OPEN_MODAL", payload: {titleNo: title_no, boardType: board_type, currentPageType: pageType, created: pageCreated}})
						}
					}
				}
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}
export const _realStoreHopin = (dispatch, title_no, board_type, expire_date, history, page_type, created = false) => {
	//로딩
	fetch(`${BJ_API_DOMAIN}/${global.bj_id}/home/pin`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ title_no, board_type, expire_date, page_type, created, _token: cookie.load("XSRF-TOKEN") }),
		credentials: "include",
	})
		.then(handleFetchErrors, handleFailedToFetch)
		.then((response) => response.json())
		.then((response) => {
			dispatch({
				type: STORE_PIN_SUCCESS,
				payload: {title_no: title_no, board_type: board_type},
			});

			dispatch({
				type: STORE_PIN_BOTTOM_SUCCESS,
				payload: {title_no: title_no, board_type: board_type},
			});

			toastr.success(response.message, {
				timeOut: 1000,
			});

			dispatch({ type: 'CLOSE_MODAL' });

			if (isMobile) {
				setTimeout(() => {
					history.goBack();
				}, 300);
			}
		})
		.catch((error) => {
			toastr.error(error.message, {
				timeOut: 2000,
			});
		});
};

/**
 * 홈에고정 삭제
 * @param {*} bj_id
 */
export function destroyHomePin(title_no, board_type, location = '', created = false) {
	return (dispatch) => {
		if (window.confirm(i18next.t("고정을 해제하시겠습니까?"))) {
			dispatch({ type: DESTORY_PIN });
			//로딩
			fetch(`${BJ_API_DOMAIN}/${global.bj_id}/home/pin/${title_no}${board_type === 105 ? `?location=${location}&created=${created}` : ''}`, {
				method: POST,
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: querystring.stringify({ _method: DELETE, board_type, _token: cookie.load("XSRF-TOKEN") }),
				credentials: "include",
			})
				.then(handleFetchErrors, handleFailedToFetch)
				.then((response) => response.json())
				.then((response) => {
					dispatch({
						type: DESTORY_PIN_SUCCESS,
						payload: {title_no: title_no, board_type: board_type},
					});

					dispatch({
						type: DESTORY_PIN_BOTTOM_SUCCESS,
						payload: {title_no: title_no, board_type: board_type},
					});

					toastr.success(response.message, {
						timeOut: 1000,
					});
				})
				.catch((error) => {
					toastr.error(error.message, {
						timeOut: 2000,
					});
				});
		}
	};
}
