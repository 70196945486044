import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-jalaali';
import ModalWindow from "./ModalWindow";
import DatePicker from 'react-datepicker2';
import { _realStoreHopin } from "actions/home.action";
import { toastr } from "./toastr";

const FixedHomeExpiryLayer = () => {
    const dispatch = useDispatch();

    const { open, titleNo, boardType, currentPageType:pageType, created } = useSelector(({ pinLayerReducer }) => ({
        open: pinLayerReducer.open,
        titleNo: pinLayerReducer.titleNo,
        boardType: pinLayerReducer.boardType,
        currentPageType: pinLayerReducer.currentPageType,
        created: pinLayerReducer.created
    }));

    const [date, setDate] = useState(moment());
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('0');
    const [expireSetting, setExpireSetting] = useState(false);
    const [calendarLayerOpen, setCalendarLayerOpen] = useState(false);
    const [hourLayerOpen, setHourLayerOpen] = useState(false);
    const [minuteLayerOpen, setMinuteLayerOpen] = useState(false);
    const datePickerRef = useRef(null);
    const selectBoxRef = useRef([]);

    useEffect(() => {
        setCalendarLayerOpen(false);
        setHourLayerOpen(false);
        setMinuteLayerOpen(false);
        setExpireSetting(false);
        const nextDateTime = getNextDateTime();
        const [fullDate, time] = nextDateTime.split(' ');
        const [selectedHour] = time.split(':');

        if (!open) {
            // close 될떄는.. 조금 늦게 데이터 초기화
            setTimeout(() => {
                setDate(moment(fullDate, 'YYYY-MM-DD'));
                setHour(selectedHour);
                setMinute('0');
            }, 400);
        } else {
            setDate(moment(fullDate, 'YYYY-MM-DD'));
            setHour(selectedHour);
            setMinute('0');
        }

    }, [open]);

    useEffect(() => {
        const handleOutSideClick = (e) => {
            const isOutsideHourSelect = !selectBoxRef.current[0]?.contains(e.target);
            const isOutsideMinuteSelect = !selectBoxRef.current[1]?.contains(e.target);

            if (isOutsideHourSelect) {
                setHourLayerOpen(false);
            }
            if (isOutsideMinuteSelect) {
                setMinuteLayerOpen(false);
            }
        };

        document.addEventListener('click', handleOutSideClick);
        return () => document.removeEventListener('click', handleOutSideClick);
    }, []);


    function getNextDateTime() {
        const now = moment();
        const nextDate = now.clone().add(1, 'days').startOf('hour');
        return nextDate.format('YYYY-MM-DD HH:0');
    }

    const handleDateChange = (newDate, e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation(); // 이벤트 전파 방지
        }
        setDate(moment(newDate));

    }

    const handleExpireCheck = (event) => {
        setExpireSetting(event.target.checked);
    }

    const handleStorePin = () => {
        const expireDate = `${date.format('YYYY-MM-DD')} ${hour}:${minute === '0' ? '00' : minute}`;
        const selectedDateTime = moment(`${date.format('YYYY-MM-DD')} ${hour}:${minute === '0' ? '00' : minute}`, 'YYYY-MM-DD HH:mm');
        const currentDateTime = moment();

        if (selectedDateTime.isBefore(currentDateTime)) {
            toastr.success('현재시간 이후를 선택해 주세요.');
            return;
        }

        _realStoreHopin(dispatch, titleNo, boardType, expireSetting ? null :expireDate, null, pageType, created);
    }

    // FixedHomeExpiryLayer 내부에서 이벤트 전파 방지
    const handleLayerClick = (e) => {
        e.stopPropagation(); // 이벤트 전파 방지
    };

    return (
        <ModalWindow open={open} onClose={() => {
            dispatch({type: 'CLOSE_MODAL'});
            setCalendarLayerOpen(false);
        }} >
            {(close) => (
                <div className="ui-pop pinExpire" onClick={handleLayerClick}>
                    <div className="pop-title">
                        <h3>홈 고정 만료일</h3>
                    </div>
                    <div className="pop-body">
                        <div className="cont_text">
                            <p>홈 고정 콘텐츠는 설정한 만료일 이후에는 자동으로 해지됩니다.</p>
                        </div>
                        <div className={`datePicker_wrap ${expireSetting ? 'disabled' : ''}`}>
                            <div className="btn_calendar">
                                {calendarLayerOpen ? (
                                    <DatePicker
                                        ref={datePickerRef}
                                        min={moment().startOf("day")}
                                        onChange={(newDate, e) => handleDateChange(newDate, e)}
                                        value={date}
                                        isGregorian={true}
                                        timePicker={false}
                                        inputFormat="YYYY-MM-DD" // 입력 형식을 YYYY-MM-DD로 설정
                                        inputProps={{
                                            readOnly: true,
                                            onClick: (e) => e.stopPropagation(), // `DatePicker` 내부 클릭 시 이벤트 전파 방지
                                        }}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        value={date.format("YYYY-MM-DD")}
                                        readOnly
                                        onClick={(e) => {
                                            setCalendarLayerOpen(true);
                                            setTimeout(()=>{
                                                datePickerRef.current?.setOpen(true);

                                            },0)
                                        }}
                                        className="datepicker-input"
                                    />
                                )}
                                <label htmlFor={'datetime'} className={'datetime'}>{date.format("YYYY-MM-DD")}</label>
                            </div>
                            {/* 시 선택 영역 */}
                            <div className={`select-box ${hourLayerOpen ? "active" : ""}`}>
                                <button
                                    ref={(element) => selectBoxRef.current[0] = element}
                                    type="button"
                                    className="selected"
                                    onClick={() => setHourLayerOpen(!hourLayerOpen)}>
                                    {hour ? `${hour}시` : "시 선택"}
                                </button>
                                {hourLayerOpen && (
                                    <div className="select-layer">
                                        <ul className="scrollarea-content" data-align="left">
                                            {[...Array(24).keys()].map((num) => (
                                                <li key={num} className={hour === String(num) ? "on" : ""}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setHour(String(num));
                                                            setHourLayerOpen(false);
                                                        }}
                                                    >
                                                        {num}시
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {/* 분 선택 영역 */}
                            <div className={`select-box ${minuteLayerOpen ? 'active' : ''}`}>
                                <button
                                    ref={(element) => selectBoxRef.current[1] = element}
                                    type="button"
                                    className="selected"
                                    onClick={() => setMinuteLayerOpen(!minuteLayerOpen)}>
                                    {minute ? `${minute}분` : '분 선택'}
                                </button>
                                {minuteLayerOpen && (
                                    <div className="select-layer">
                                        <ul className="scrollarea-content" data-align="left">
                                            {["0", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"].map((min) => (
                                                <li key={min} className={minute === min ? "on" : ""}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setMinute(min);
                                                            setMinuteLayerOpen(false);
                                                        }}
                                                    >
                                                        {min}분
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="chk_wrap">
                            <input type="checkbox" id="set01" onClick={handleExpireCheck} />
                            <label htmlFor="set01">만료 설정 안함</label>
                        </div>
                    </div>
                    <div className="pop-btn">
                        <button type="button" className="btn-basic blue" onClick={handleStorePin}><span>적용</span></button>
                    </div>
                    <a href="javascript:void(0)" className="pop-close" onClick={() => {
                        dispatch({type: 'CLOSE_MODAL'});
                        setCalendarLayerOpen(false);
                    }}>
                        <span>닫기</span>
                    </a>
                </div>
            )}
        </ModalWindow>
    );
}

export default FixedHomeExpiryLayer;
