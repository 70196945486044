export const WRAP_CLASSNAME = "WRAP_CLASSNAME";

export const FIX_BAR_CLASS = "fix_bar";
export const MAIN_CLASS = "main";
export const MODAL = "modal";
export const WRITE_CLASS = "write";
/**
 * 페이지 이동알림
 * @param {*} bbs_no
 */
export function wrapClassName(classname = "") {
	let classList = classname;
	if (Array.isArray(classname)) {
		classList = classname.join(" ");
	}

	return (dispatch) => {
		dispatch({
			type: WRAP_CLASSNAME,
			bsWrapClassName: classList,
		});
	};
}
