import { isMobile } from "react-device-detect";
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const INITIAL_STATE = {
    mobileOpen: false,
    open: false,
    titleNo: 0,
    boardType: 0,
    currentPageType: '',
    created: false
};

const pinLayerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return { ...state,  open: !isMobile, mobileOpen: isMobile, titleNo: action.payload.titleNo, boardType: action.payload.boardType, currentPageType: action.payload.currentPageType, created: action.payload.created};
        case CLOSE_MODAL :
            return {...state,  open: false, mobileOpen: false};
        default:
            return state;
    }
};

export default pinLayerReducer;
