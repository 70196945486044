const ICT_OPEN_MODAL = 'ICT_OPEN_MODAL';
const ICT_CLOSE_MODAL = 'ICT_CLOSE_MODAL';

const LATER_OPEN_MODAL = 'LATER_OPEN_MODAL';
const LATER_CLOSE_MODAL = 'LATER_CLOSE_MODAL';

const INITIAL_STATE = {
    open: false,
    laterButtonOpen: false,
    menuData: null,
    children: null,
    titleNo: 0,
    top: 0,
    left: 0,
}

const homeIctMenuReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case ICT_OPEN_MODAL:
            return { ...state,  open: true, menuData: action.payload.item, top: action.payload.top, left: action.payload.left, children: action.payload.children};
        case ICT_CLOSE_MODAL :
            return {...state,  open: false, menuData: null};

        case LATER_OPEN_MODAL:
            return {...state, laterButtonOpen: true, titleNo: action.payload.titleNo, top: action.payload.top, left: action.payload.left};

        case LATER_CLOSE_MODAL:
            return {...state,  laterButtonOpen: false};
        default:
            return state;
    }
}

export default homeIctMenuReducer;